/* External dependencies */
import { Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

interface TitleProps {
  tag?: string;
  title: any;
}

export default function MainTitle({ tag = 'h2', title }: TitleProps) {
  return (
    <Text
      tag={tag}
      textSize={{ xs: 'subtitle', md: 'title' }}
      textWeight="600"
      m={{ b: '50px' }}
    >
      <Trans>{title}</Trans>
    </Text>
  );
}
