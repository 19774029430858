/* External dependencies */
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

/* Local dependencies */
import About from '../../components/website/about';
import Footer from '../../components/website/footer/Footer';
import Header from '../../components/website/header/Header';
import Layout from '../../shared/ui/layout/WebLayout';
import SEO from '../../shared/ui/layout/seo';

export default function Home() {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO
        title={t('aboutPageTitle')}
        description={t('aboutPageContent')}
        image="medcheck.png"
      />
      <Header />
      <About />
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
