/* External dependencies */
import { Container, Div, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import MainTitle from '@shared/ui/titles/MainTitle';

export default function About() {
  const targetList = ['ourGoals1', 'ourGoals2', 'ourGoals3', 'ourGoals4'];

  return (
    <main>
      <Container p={{ y: '110px' }}>
        <MainTitle title="about" tag="h1" />
        <Div>
          <Text m={{ b: '20px' }} tag="p">
            <Text textWeight="600" textSize="18px" tag="span" d="inline">
              Medcheck
            </Text>
            <Trans>aboutMedcheckTextInAboutPage</Trans>
          </Text>
          <Text m={{ b: '20px' }} tag="p" d="flex">
            <Text
              textWeight="600"
              tag="span"
              d="inline-block"
              m={{ r: '10px' }}
            >
              <Trans>ourMissionText</Trans>
            </Text>
            <Trans>ourMission</Trans>
          </Text>
          <Text m={{ b: '10px' }} textWeight="600">
            <Trans>ourGoals</Trans>
          </Text>
          <ul style={{ margin: '0', padding: '0' }}>
            {targetList.map((item, index) => (
              <li
                key={index}
                style={{ listStyleType: 'none', marginBottom: '10px' }}
              >
                <Trans>{item}</Trans>
              </li>
            ))}
          </ul>
        </Div>
      </Container>
    </main>
  );
}
